'use client'

import {
	type IntersectionObserverOptions,
	useIntersectionObserver,
} from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { useEffect, useRef } from 'react'

import { sendGenericEvent } from '../../events/generics/sendGenericEvent'

type ViewElementProps = {
	readonly children: React.ReactElement
	readonly eventName: string
	readonly eventData?: Record<string, string>
	readonly options?: IntersectionObserverOptions
	readonly className?: string
}

export const ViewElement = ({
	children,
	eventData = {},
	eventName,
	options = {},
}: ViewElementProps) => {
	const ref = useRef(null)
	const entry = useIntersectionObserver(ref, options)

	useEffect(() => {
		if (entry?.isIntersecting) {
			sendGenericEvent(eventName, eventData)
		}
	}, [entry?.isIntersecting])

	return <div ref={ref}>{children}</div>
}
