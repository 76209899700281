import { BUTTON_VARIANT } from 'fukku/Button/Button.types'
import { ButtonBase } from 'fukku/Button/ButtonBase'
import { applyStylesIf, cx } from 'utils/cx'

import styles from './InspirationalButtonLink.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface InspirationalLinkProps extends React.ComponentProps<'button'> {
	readonly variant?: BUTTON_VARIANT
	readonly active?: boolean
	readonly isTopBar?: boolean
}

export function InspirationalButtonLink(
	props: InspirationalLinkProps
): React.ReactNode {
	const {
		children,
		disabled,
		active,
		variant = BUTTON_VARIANT.DEFAULT,
		className,
		isTopBar = false,
		...rest
	} = props

	return (
		<ButtonBase
			{...rest}
			className={cx(
				className,
				styles[variant],
				text.uppercaseM,
				applyStylesIf(active === true && !disabled, styles.active),
				applyStylesIf(active === false && !disabled, styles.unselected),
				applyStylesIf(isTopBar, styles.topBar)
			)}
			disabled={disabled}
		>
			<span className={styles.text}>{children}</span>
		</ButtonBase>
	)
}
