'use client'

import { type CountryFieldValidationsResponse } from 'country-configuration/types/FieldValidations.type'
import { createContext } from 'react'

export const FieldValidationsContext = createContext<
	CountryFieldValidationsResponse | undefined
>(undefined)

interface FieldValidationsProviderProps {
	readonly fieldValidations: CountryFieldValidationsResponse | undefined
	readonly children: React.ReactNode
}

export function FieldValidationsProvider({
	fieldValidations,
	children,
}: FieldValidationsProviderProps): React.ReactNode {
	return (
		<FieldValidationsContext.Provider value={fieldValidations}>
			{children}
		</FieldValidationsContext.Provider>
	)
}
