'use client'

import { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import { useFeatureFlag } from 'feature-flags/hooks/useFeatureFlag/useFeatureFlag'
import dynamic from 'next/dynamic'
import { Suspense } from 'react'

import { useShowBanner } from '../hooks/useShowBanner'

const BannerWrapperDynamic = dynamic(() =>
	import('./BannerWrapper/BannerWrapper').then((mod) => mod.BannerWrapper)
)

export const BannerApp = () => {
	const { showBanner, closeBanner } = useShowBanner()
	const { enabled } = useFeatureFlag(featureFlagKeys.BannerApp)

	if (!showBanner || !enabled) {
		return null
	}

	return (
		<Suspense>
			<BannerWrapperDynamic closeAction={closeBanner} />
		</Suspense>
	)
}
