import { useEffect } from 'react'
import { useHoverIntent } from 'react-use-hoverintent'

import type { HoverDelayInspiration } from '../constants/hoverDelays'

export function useHover<T>(
	delay: HoverDelayInspiration,
	callbackHover?: () => void
) {
	const [isHovering, ref] = useHoverIntent<T>({
		interval: delay,
	})

	useEffect(() => {
		if (isHovering) {
			callbackHover?.()
		}
	}, [isHovering])

	return { ref, isHovering }
}
