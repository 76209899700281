'use client'

import { useEffect, useRef, useState } from 'react'
import type { RefObject } from 'react'

const DEFAULT_HEADER_HEIGHT = 56
const NO_HEADER_VALUE = 0

const setStylesVariable = (headerHeight: number) => {
	document.documentElement.style.setProperty(
		'--header-height',
		`${headerHeight}px`
	)
}

export const useHeaderHeight = (): {
	headerHeight: number
	headerRef: RefObject<HTMLHeadElement>
} => {
	const [headerHeight, setHeaderHeight] = useState<number>(
		DEFAULT_HEADER_HEIGHT
	)
	const headerRef = useRef<HTMLHeadElement | null>(null)

	useEffect(() => {
		const headerElement = document.getElementById('header')
		headerRef.current = headerElement

		const updateHeaderHeight = () => {
			if (!headerElement) {
				setHeaderHeight(NO_HEADER_VALUE)
				setStylesVariable(NO_HEADER_VALUE)
				return
			}
			if (headerRef.current) {
				const { height } = headerRef.current.getBoundingClientRect()
				setHeaderHeight(height)
				setStylesVariable(height)
			}
		}

		updateHeaderHeight()

		const resizeObserver = new ResizeObserver(updateHeaderHeight)

		if (headerRef.current) {
			resizeObserver.observe(headerRef.current)
		}

		return () => {
			resizeObserver.disconnect()
		}
	}, [])

	return {
		headerHeight,
		headerRef,
	}
}
