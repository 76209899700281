'use client'

import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { HREF } from '../constants/constants'

type FavoritesClientLinkProps = Omit<
	ClientLinkProps,
	'href' | 'withLegacyLocale'
> & {
	readonly linkComponent?: React.ElementType
}

export function FavoritesClientLink({
	children,
	linkComponent,
	...linkProps
}: FavoritesClientLinkProps) {
	const Component = linkComponent || ClientLink
	return (
		<Component {...linkProps} href={HREF}>
			{children}
		</Component>
	)
}
