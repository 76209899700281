'use client'

import { createContext, useCallback, useState } from 'react'

import type { MenuEntry } from '../../types/menu'

type MenuState = {
	menus: MenuEntry[] | undefined
}

const INITIAL_STATE: MenuState = {
	menus: undefined,
}

type MenuDispatch = (newState: Partial<MenuState>) => void

const INITIAL_DISPATCH: MenuDispatch = () => {
	/* */
}

type MenuProviderProps = {
	readonly children: React.ReactNode
}

export const MenuDataStateContext = createContext<MenuState>(INITIAL_STATE)
export const MenuDataDispatchContext =
	createContext<MenuDispatch>(INITIAL_DISPATCH)

export const MenuDataProvider = ({ children }: MenuProviderProps) => {
	const [state, setState] = useState(INITIAL_STATE)
	const dispatch = useCallback(
		(newState: Partial<MenuState>) =>
			setState((prevState) => ({ ...prevState, ...newState })),
		[]
	)

	return (
		<MenuDataStateContext.Provider value={state}>
			<MenuDataDispatchContext.Provider value={dispatch}>
				{children}
			</MenuDataDispatchContext.Provider>
		</MenuDataStateContext.Provider>
	)
}
