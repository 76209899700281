'use client'

import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

export function Responsive({
	onSmall = false,
	onMedium = false,
	onLarge = false,
	onLessThanLarge = false,
	onLargeOrGreater = false,
	onLessThanExtraLarge = false,
	onExtraLargeOrGreater = false,
	onTouchable = false,
	onCursor = false,
	children,
}: {
	readonly onSmall?: boolean
	readonly onMedium?: boolean
	readonly onLarge?: boolean
	readonly onLessThanLarge?: boolean
	readonly onLargeOrGreater?: boolean
	readonly onLessThanExtraLarge?: boolean
	readonly onExtraLargeOrGreater?: boolean
	readonly onTouchable?: boolean
	readonly onCursor?: boolean
	readonly children: React.ReactNode
}) {
	const {
		isSmall,
		isMedium,
		isLarge,
		isLargeOrGreater,
		isLessThanLarge,
		isExtraLargeOrGreater,
		isLessThanExtraLarge,
		isTouchable,
	} = useResponsive()
	if (
		[
			onExtraLargeOrGreater && isExtraLargeOrGreater,
			onLessThanExtraLarge && isLessThanExtraLarge,
			onLargeOrGreater && isLargeOrGreater,
			onLessThanLarge && isLessThanLarge,
			onLarge && isLarge,
			onMedium && isMedium,
			onSmall && isSmall,
			onTouchable && isTouchable,
			onCursor && !isTouchable,
		].some((condition) => condition)
	) {
		return children
	}
	return null
}
