'use client'

import { BUTTON_VARIANT } from 'fukku/Button/Button.types'
import { applyStylesIf, cx } from 'utils/cx'
import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import styles from './InspirationalLink.module.scss'
import text from 'fukku/styles/texts.module.scss'

type ClientInspirationalLinkProps = ClientLinkProps & {
	readonly variant?: BUTTON_VARIANT
	readonly active?: boolean
	readonly isTopBar?: boolean
}

export function InspirationalLink(
	props: ClientInspirationalLinkProps
): React.ReactNode {
	const {
		children,
		active,
		variant = BUTTON_VARIANT.DEFAULT,
		className,
		isTopBar = false,
		...rest
	} = props

	return (
		<ClientLink
			{...rest}
			className={cx(
				className,
				styles[variant],
				text.uppercaseM,
				applyStylesIf(active === true, styles.active),
				applyStylesIf(active === false, styles.unselected),
				applyStylesIf(isTopBar, styles.topBar)
			)}
		>
			<span className={styles.text}>{children}</span>
		</ClientLink>
	)
}
