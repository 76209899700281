import type { NavigationLink } from '../components/NavigationLinks/NavigationLinks'

type ReorderNavigationGridProps = {
	initialColumn: number
	initialRow: number
	item: NavigationLink
}

export function reorderNavigationLinks(
	content: NavigationLink[],
	columns: number
): NavigationLink[] {
	const rows = Math.ceil(content.length / columns)
	const maxItemsLastRow = content.length % columns
	const contentMatrix: (NavigationLink | null)[][] = Array.from(
		{ length: rows },
		() => Array.from({ length: columns }, () => null)
	)

	function populateContentMatrix({
		initialColumn,
		initialRow,
		item,
	}: ReorderNavigationGridProps): void {
		const isLastRow = initialRow === rows - 1
		const checkMaxItemsLastRow = maxItemsLastRow !== 0 && isLastRow

		if (checkMaxItemsLastRow && initialColumn >= maxItemsLastRow) {
			return populateContentMatrix({
				initialColumn: initialColumn + 1,
				initialRow: 0,
				item,
			})
		}

		const hasValue = contentMatrix[initialRow][initialColumn] !== null
		if (hasValue) {
			return populateContentMatrix({
				initialRow: initialRow + 1,
				initialColumn,
				item,
			})
		}

		contentMatrix[initialRow][initialColumn] = item
	}

	content.forEach((item, index) => {
		const row = index % rows
		const column = Math.floor(index / rows)

		populateContentMatrix({
			initialColumn: column,
			initialRow: row,
			item,
		})
	})

	return contentMatrix
		.flat()
		.filter((item): item is NavigationLink => item !== null)
}
