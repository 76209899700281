'use client'

import { createContext, useCallback, useState } from 'react'

export enum MenuRefType {
	CONTENT = 'refContent',
	BRANDS = 'refBrands',
	CLOSE_BUTTON = 'refCloseButton',
}

export type MenuRefState = {
	refContent: React.RefObject<HTMLDivElement>
	refBrands: React.RefObject<HTMLUListElement>
	refCloseButton: React.RefObject<HTMLButtonElement>
}

const defaultRefContent = {
	current: null,
}

const INITIAL_STATE: MenuRefState = {
	refContent: defaultRefContent,
	refBrands: defaultRefContent,
	refCloseButton: defaultRefContent,
}

type MenuDispatch = (newState: Partial<MenuRefState>) => void

const INITIAL_DISPATCH: MenuDispatch = () => {
	/* */
}

type MenuProviderProps = {
	readonly children: React.ReactNode
}

export const MenuRefStateContext = createContext<MenuRefState>(INITIAL_STATE)
export const MenuRefDispatchContext =
	createContext<MenuDispatch>(INITIAL_DISPATCH)

export const MenuRefProvider = ({ children }: MenuProviderProps) => {
	const [state, setState] = useState(INITIAL_STATE)
	const dispatch = useCallback(
		(newState: Partial<MenuRefState>) =>
			setState((prevState) => ({ ...prevState, ...newState })),
		[]
	)

	return (
		<MenuRefStateContext.Provider value={state}>
			<MenuRefDispatchContext.Provider value={dispatch}>
				{children}
			</MenuRefDispatchContext.Provider>
		</MenuRefStateContext.Provider>
	)
}
