import { cx } from 'utils/cx'

import { ButtonBase } from '../ButtonBase'
import {
	BUTTON_INSPIRATIONAL_STATE,
	BUTTON_INSPIRATIONAL_VARIANT,
	stateStyles,
} from './constants'

import styles from './ButtonInspirational.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface ButtonInspirationalProps
	extends Omit<React.ComponentProps<'button'>, 'disabled'> {
	readonly variant?: BUTTON_INSPIRATIONAL_VARIANT
	readonly state?: BUTTON_INSPIRATIONAL_STATE
}

export function ButtonInspirational(
	props: ButtonInspirationalProps
): React.ReactNode {
	const {
		children,
		state = BUTTON_INSPIRATIONAL_STATE.DEFAULT,
		variant = BUTTON_INSPIRATIONAL_VARIANT.DEFAULT,
		className,
		...rest
	} = props

	return (
		<ButtonBase
			{...rest}
			className={cx(
				className,
				styles.buttonInspirational,
				styles[variant],
				text.uppercaseM,
				stateStyles[state]
			)}
			disabled={state === BUTTON_INSPIRATIONAL_STATE.DISABLED}
		>
			<span className={styles.text}>{children}</span>
		</ButtonBase>
	)
}
