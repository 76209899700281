'use client'

import { brandAdapter } from 'header/constants/analytics/brandAdapter'
import { HoverDelayInspiration } from 'header/constants/hoverDelays'
import { useHover } from 'header/hooks/useHover'
import { useMenus } from 'header/hooks/useMenus'
import type { EntryClickMenuEventData, MenuEntry } from 'header/types/menu'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { EntryLink } from './EntryLink'
import { EntrySubmenu } from './EntrySubmenu'

import styles from './Entry.module.scss'

type EntryProps = {
	readonly section: MenuEntry
	readonly isSelected: boolean | undefined
	readonly dataTestIdCategory: string
	readonly analyticsCategory: { section: string; subsection?: string }
	readonly onClick: () => void
	readonly handleKeyDown?: (event: React.KeyboardEvent) => void
}

export function Entry({
	section,
	isSelected,
	dataTestIdCategory,
	analyticsCategory,
	onClick,
	handleKeyDown,
}: EntryProps) {
	const { menus, style } = section
	const { isLargeOrGreater } = useResponsive()
	const { brandSelected, subBrandSelected } = useMenus()
	const { ref: refEntry } = useHover<HTMLDivElement>(
		HoverDelayInspiration.ENTRIES,
		onClick
	)

	const isPromotion = style?.includes('emphasis') || false
	const className = styles.entry

	const getBrandsEventData = (): { brand: string; subbrand?: string } => {
		let eventData: { brand: string; subbrand?: string } = {
			brand: brandAdapter[brandSelected as string],
		}

		if (subBrandSelected) {
			eventData = { ...eventData, subbrand: brandAdapter[subBrandSelected] }
		}
		return eventData
	}

	const entryEventData: EntryClickMenuEventData = {
		...getBrandsEventData(),
		...analyticsCategory,
	}

	return (
		<div
			ref={isLargeOrGreater ? refEntry : undefined}
			className={styles.entryWrapper}
		>
			{menus ? (
				<EntrySubmenu
					section={section}
					className={className}
					isSelected={isSelected}
					dataTestIdCategory={dataTestIdCategory}
					eventData={entryEventData}
					onClick={onClick}
					handleKeyDown={handleKeyDown}
					isPromotion={isPromotion}
				/>
			) : (
				<EntryLink
					section={section}
					className={className}
					isSelected={isSelected}
					dataTestIdCategory={dataTestIdCategory}
					eventData={entryEventData}
					handleKeyDown={handleKeyDown}
					isPromotion={isPromotion}
				/>
			)}
		</div>
	)
}
