import type { MenuEntry } from '../../types/menu'

const STYLE_TEST_AB = 'testAB'
const STYLE_SEPARATOR = ':'

export function getTestABLabel(
	entry: MenuEntry,
	defaultValue: string,
	isActive = false
) {
	const { style } = entry
	if (!style) {
		return defaultValue
	}

	const existing = style.find((item) => item.includes(STYLE_TEST_AB))

	if (!existing) {
		return defaultValue
	}

	if (!isActive) {
		return defaultValue
	}

	return existing.split(STYLE_SEPARATOR)[1]
}
