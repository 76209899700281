'use client'

import { IconKeyboardNext } from 'icons/components/IconKeyboardNext'

import styles from './CountryLanguageButton.module.scss'
import text from 'fukku/styles/texts.module.scss'

type CountryLanguageButtonProps = {
	readonly onClick: () => void
	readonly countryName: string
	readonly isMultiLanguage: boolean
	readonly languageName: string
}

export const CountryLanguageButton = ({
	onClick,
	countryName,
	isMultiLanguage,
	languageName,
}: CountryLanguageButtonProps) => {
	return (
		<button
			className={styles.button}
			onClick={onClick}
			data-testid='changeButton'
		>
			<span className={styles.buttonContent}>
				<span className={styles.countryTextMobile}>
					<span className={`${styles.countryText} ${text.uppercaseM}`}>
						{countryName}
						{isMultiLanguage && <span aria-hidden>·</span>}
					</span>
					{isMultiLanguage && (
						<span className={`${styles.countryText} ${text.uppercaseM}`}>
							{languageName}
						</span>
					)}
				</span>
				<IconKeyboardNext className={styles.icon} rotateRtl />
			</span>
		</button>
	)
}
