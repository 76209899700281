'use client'

import type { IconProps } from 'icons/types/Icon.types'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import dynamic from 'next/dynamic'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { Device } from 'types/masterData'

import { BRAND } from '../utils/brand'
import { logoAltText } from '../utils/logoAltText'
import { LOGO_NAME, logoName } from '../utils/logoName'
import { logoSize } from '../utils/logoSize'

const IconLogoMango = dynamic<IconProps>(() =>
	import('icons/components/IconLogoMango').then((mod) => mod.IconLogoMango)
)
const IconLogoMng = dynamic<IconProps>(() =>
	import('icons/components/IconLogoMng').then((mod) => mod.IconLogoMng)
)
const IconLogoOutlet = dynamic<IconProps>(() =>
	import('icons/components/IconLogoOutlet').then((mod) => mod.IconLogoOutlet)
)

type LogoType = {
	[key in LOGO_NAME]: (props: IconProps) => React.ReactNode
}

const LogoIcon: LogoType = {
	[LOGO_NAME.Mango]: (props) => <IconLogoMango {...props} />,
	[LOGO_NAME.Outlet]: (props) => <IconLogoOutlet {...props} />,
	[LOGO_NAME.Mng]: (props) => <IconLogoMng {...props} />,
}

export const useLogoProperties = () => {
	const {
		channel,
		country: { mngLogo },
	} = useMasterData()
	const { isLargeOrGreater } = useResponsive()

	const name = logoName[channel][mngLogo ? BRAND.Mng : BRAND.Mango]
	const altText = logoAltText[channel][mngLogo ? BRAND.Mng : BRAND.Mango]
	const size =
		logoSize[channel][isLargeOrGreater ? Device.Desktop : Device.Mobile]
	const { width, height } = size

	return {
		name,
		altText,
		LogoComponent: LogoIcon[name || LOGO_NAME.Mango],
		width: width[mngLogo ? BRAND.Mng : BRAND.Mango],
		height: height[mngLogo ? BRAND.Mng : BRAND.Mango],
	}
}
