export enum LabelsNamespaces {
	Accessibility = 'Accessibility',
	Apps = 'Apps',
	ATC_Form = 'ATC_form',
	Brands = 'Brands',
	Campaigns_Promotions = 'Campaigns_Promotions',
	Checkout = 'Checkout',
	Client = 'Client',
	Cookies = 'Cookies',
	Discover = 'Discover_by_Mango',
	Edit_Woman_ComfortLuxe_SS25 = 'Edit_Woman_ComfortLuxe_SS25',
	Filters = 'Filters',
	Footer = 'Footer',
	Forms = 'Forms',
	Generic_Editorial_Translations = 'Generic_Editorial_Translations',
	Gift_Voucher = 'Gift_card',
	Header = 'Header',
	Homes_Outlet = 'Homes_Outlet',
	Homes_Shop = 'Homes_Shop',
	Landing_Mango_Likes_You = 'Landing_Mango_Likes_You',
	Legal = 'Legal_Texts',
	Loyalty = 'Loyalty',
	Menu_Common = 'Menu_Common_Keys',
	Menu_Outlet = 'Menu_outlet',
	Menu_Shop = 'Menu_shop',
	Modal_conditions_PDP = 'Modal_conditions_PDP',
	MLY_Club_Mango_Likes_You = 'Club_Mango_Likes_You',
	MLY_PartnersONG = 'MLY_PartnersONG',
	My_Addresses = 'My_addresses',
	My_Details = 'My_details',
	My_profile = 'My_profile',
	My_purchases = 'My_purchases',
	Navigation = 'Navigation',
	Notify_me = 'Notify_me',
	Payment_Checkout = 'Payment_Checkout',
	Performance = 'Performance',
	Prehome_Country_Selector = 'Prehome_Country_Selector',
	Prices = 'Prices',
	Product_Card = 'Product_card',
	Promos_Alerts_Checkout = 'Promos_Alerts_Checkout',
	Promotions_Outlet = 'Outlet_Promotions',
	Promotions_Shop = 'Promotions_Shop',
	Post_Purchase = 'Post_Purchase',
	Pdp = 'PDP',
	Plp = 'PLP',
	Plp_Pdp = 'Plp_Pdp',
	Purchase_confirmation = 'Purchase_confirmation',
	Returns_and_refunds = 'Returns_and_refunds',
	Search_Bar = 'Search_Bar',
	Shopping_Bag = 'Shopping_Bag',
	Shopping_Bag_Checkout = 'Shopping_Bag_Checkout',
	Signin_Create_account = 'Signin_Create_account',
	Size_Guide = 'Size_Guide',
	Store_Locator = 'Store_locator',
	Subscribe_Newsletter = 'Subscribe_Newsletter',
	Transversal_Keys = 'Transversal_Keys',
	USA_privacy_form = 'USA_privacy_form',
	Wishlist = 'Wishlist',
	Backoffice = 'Backoffice',
	Backoffice_Akamai = 'Backoffice_Akamai',
	Backoffice_Bart = 'Backoffice_Bart',
	Backoffice_Garment_Sorter = 'Backoffice_Garment_Sorter',
	Backoffice_Juice = 'Backoffice_Juice',
	Backoffice_Labels = 'Backoffice_Labels',
	Backoffice_Login = 'Backoffice_Login',
	Backoffice_Mambo = 'Backoffice_Mambo',
	Backoffice_Navigation = 'Backoffice_Navigation',
	Backoffice_Payments_Post_Sale = 'Backoffice_Payments_Post_Sale',
	Backoffice_Physical_Store = 'Backoffice_Physical_Store',
	Backoffice_Product_Color_Group = 'Backoffice_Product_Color_Group',
	Backoffice_Product_Enable_Selling = 'Backoffice_Product_Enable_Selling',
	Backoffice_Product_Families = 'Backoffice_Product_Families',
	Backoffice_Product_Promo_Sales = 'Backoffice_Product_Promo_Sales',
	Backoffice_Product_Promotions_Messages = 'Backoffice_Product_Promotions_Messages',
	Backoffice_Product_Shared = 'Backoffice_Product_Shared',
	Backoffice_Product_Sheet = 'Backoffice_Product_Sheet',
	Backoffice_Product_Tags = 'Backoffice_Product_Tags',
	Backoffice_Product_Total_Look = 'Backoffice_Product_Total_Look',
	Backoffice_Product_Ungroup = 'Backoffice_Product_Ungroup',
	Backoffice_Promo_Codes = 'Backoffice_Promo_Codes',
	Backoffice_Sales_Statistics = 'Backoffice_Sales_Statistics',
	Backoffice_Seo = 'Backoffice_Seo',
	Backoffice_Shared = 'Backoffice_Shared',
	Help_Chatbot = 'UX_Help_Chatbot',
}

export type Labels<T extends string = string> = Record<T, string>

export type Plural = {
	value: string
}

export type Plurals = Record<string, Plural>
