// This component has been autogenerated with 'generate:components' script
import type { IconProps } from 'icons/types/Icon.types'
import { DEFAULT_ICON_COLOR, DEFAULT_ICON_SIZE } from 'icons/utils/constants'
import { cx } from 'utils/cx'

import styles from 'icons/Icon.module.scss'

export const IconChatChatS = ({
	alt,
	className,
	color = DEFAULT_ICON_COLOR,
	height = DEFAULT_ICON_SIZE,
	lang,
	width = DEFAULT_ICON_SIZE,
	rotateRtl,
}: IconProps): React.ReactNode => {
	const computedClassName = cx(
		styles[`icon-${color}`],
		rotateRtl && styles[`rotate-rtl`],
		className
	)

	return (
		<svg
			width={width}
			height={height}
			aria-hidden={alt ? undefined : 'true'}
			className={computedClassName}
			data-testid='chat-chat-s'
			xmlns='http://www.w3.org/2000/svg'
			fill='none'
			viewBox='0 0 24 24'
		>
			{alt && <title lang={lang}>{alt}</title>}
			<path fill='#131313' d='M17 7H7v1h10zm0 3.5H7v1h10zM13 14H7v1h6z' />
			<path
				fill='#131313'
				d='M3 3v16h3v3l2.8-1.9 1.7-1.1H21V3zm17 15h-9.8L7 20.1V18H4V4h16z'
			/>
		</svg>
	)
}
