'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { COOKIES_PREFERENCES, CookiesBannerStep } from 'cookies/types'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { Events } from 'webview/constants/events'
import { sendAppEvent } from 'webview/utils/sendAppEvent'

export function useCookiesBanner() {
	const { setShow, setStep, setActiveCookiePreference } = useCookiesContext()
	const { isApp } = useMasterData()

	function showCookiesBanner() {
		if (isApp) {
			sendAppEvent({ event: Events.ConsentConfiguration })
		} else {
			setStep(CookiesBannerStep.initialStep)
			setShow(true)
		}
	}

	function showCookiesConfiguration() {
		setStep(CookiesBannerStep.firstStep)
		setShow(true)
		setActiveCookiePreference(COOKIES_PREFERENCES.targeting)
	}

	function hideCookiesBanner() {
		setStep(CookiesBannerStep.initialStep)
		setShow(false)
	}

	return { showCookiesBanner, showCookiesConfiguration, hideCookiesBanner }
}
