'use client'

import { useAbTest } from 'ab-test/hooks/useAbTest/useAbTest'
import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { BUTTON_INSPIRATIONAL_VARIANT } from 'fukku/Button/Inspirational/constants'
import { ButtonInspirational } from 'fukku/Button/Inspirational/index'
import { CommonGenericEvents } from 'header/constants'
import type { EntryClickMenuEventData, MenuEntry } from 'header/types/menu'
import { getEntryState } from 'header/utils/menu/getEntryState'
import { getTestABLabel } from 'header/utils/menu/getTestABLabel'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { AbTestKeys } from 'types/constants/AbTestKeys'

type EntrySubmenuProps = {
	readonly section: MenuEntry
	readonly className: string
	readonly isSelected: boolean | undefined
	readonly dataTestIdCategory: string
	readonly eventData: EntryClickMenuEventData
	readonly onClick: () => void
	readonly handleKeyDown?: (event: React.KeyboardEvent) => void
	readonly isPromotion?: boolean
}
export function EntrySubmenu({
	section,
	className,
	isSelected,
	dataTestIdCategory,
	eventData,
	onClick,
	handleKeyDown,
	isPromotion = false,
}: EntrySubmenuProps) {
	const { id, labelId: labelIdDefault } = section
	const { t } = useLabels()
	const { isLargeOrGreater } = useResponsive()
	const abTest = useAbTest({ key: AbTestKeys.GpsMenuSectionName })
	const labelId = getTestABLabel(section, labelIdDefault, abTest?.enabled)

	const handleClick = () => {
		if (!isLargeOrGreater) {
			sendGenericEvent(CommonGenericEvents.CLICK_MENU, eventData)
		}
		onClick()
	}

	return (
		<ButtonInspirational
			className={className}
			onClick={handleClick}
			onKeyDown={handleKeyDown}
			data-testid={`menu.${dataTestIdCategory}.${id}.family`}
			state={getEntryState(isSelected)}
			variant={
				isPromotion
					? BUTTON_INSPIRATIONAL_VARIANT.PROMOTION
					: BUTTON_INSPIRATIONAL_VARIANT.DEFAULT
			}
			aria-expanded={isSelected}
		>
			{t(labelId)}
		</ButtonInspirational>
	)
}
