'use client'

import { useAbTest } from 'ab-test/hooks/useAbTest/useAbTest'
import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { InspirationalClientLink } from 'fukku/Button/ClientLink/Inspirational/index'
import { BUTTON_INSPIRATIONAL_VARIANT } from 'fukku/Button/Inspirational/constants'
import { CommonGenericEvents } from 'header/constants'
import type { EntryClickMenuEventData, MenuEntry } from 'header/types/menu'
import { determineEntryUrl } from 'header/utils/menu/determineEntryUrl'
import { getEntryState } from 'header/utils/menu/getEntryState'
import { getTestABLabel } from 'header/utils/menu/getTestABLabel'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { usePathname } from 'next/navigation'
import { AbTestKeys } from 'types/constants/AbTestKeys'

type EntryLinkProps = {
	readonly section: MenuEntry
	readonly className: string
	readonly isSelected: boolean | undefined
	readonly dataTestIdCategory: string
	readonly eventData: EntryClickMenuEventData
	readonly handleKeyDown?: (event: React.KeyboardEvent) => void
	readonly isPromotion: boolean
}

export function EntryLink({
	section,
	className,
	isSelected,
	dataTestIdCategory,
	eventData,
	handleKeyDown,
	isPromotion,
}: EntryLinkProps) {
	const { id, labelId: labelIdDefault } = section
	const { t } = useLabels()
	const url = determineEntryUrl(section)
	const pathname = usePathname()
	const abTest = useAbTest({
		key: AbTestKeys.GpsMenuSectionName,
	})
	const labelId = getTestABLabel(section, labelIdDefault, abTest?.enabled)

	const handleLinkClick = () => {
		sendGenericEvent(CommonGenericEvents.CLICK_MENU, eventData)
	}

	return (
		<InspirationalClientLink
			href={url ?? ''}
			className={className}
			isLocalizedPath
			sectionId={section.catalogId}
			linkProps={{
				'data-testid': `menu.${dataTestIdCategory}.${id}.link`,
				'aria-current': pathname === url ? 'page' : undefined,
				onKeyDown: handleKeyDown,
			}}
			state={getEntryState(isSelected)}
			onClick={handleLinkClick}
			variant={
				isPromotion
					? BUTTON_INSPIRATIONAL_VARIANT.PROMOTION
					: BUTTON_INSPIRATIONAL_VARIANT.DEFAULT
			}
		>
			{t(labelId)}
		</InspirationalClientLink>
	)
}
