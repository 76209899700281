'use client'

import { useResponsiveBehavior } from 'responsive/hooks/useResponsiveBehavior/useResponsiveBehavior'

import {
	NUMBER_COLUMNS_L_XL,
	NUMBER_COLUMNS_M,
	NUMBER_COLUMNS_S,
} from '../../constants/columns'
import { DATATESTID_PREFIX } from '../../constants/translations'
import type { LinkWithDataTestId } from '../../utils/addDataTestId'
import { reorderNavigationLinks } from '../../utils/reorderNavigationLinks'
import { NavigationLinkItem } from './NavigationLinkItem/NavigationLinkItem'

import styles from './NavigationLinks.module.scss'

type NavigationLinksProps = {
	readonly content: LinkWithDataTestId[] | undefined
}

export type NavigationLink =
	| LinkWithDataTestId
	| {
			title: string
			href?: string
			dataTestId: string
	  }

export function NavigationLinks({ content }: NavigationLinksProps) {
	const numberOfColumns = useResponsiveBehavior({
		default: NUMBER_COLUMNS_S,
		small: NUMBER_COLUMNS_S,
		medium: NUMBER_COLUMNS_M,
		large: NUMBER_COLUMNS_L_XL,
		xlarge: NUMBER_COLUMNS_L_XL,
	})

	if (!content) {
		return null
	}

	const contentWithCountry = [
		...content,
		{
			title: 'Store Locator',
			dataTestId: `${DATATESTID_PREFIX}.storeLocator.button`,
		},
	]

	const orderedContent = reorderNavigationLinks(
		contentWithCountry,
		numberOfColumns
	)

	return (
		<ul className={styles.navigationLinkList}>
			{orderedContent.map((item: NavigationLink) => {
				return (
					<li key={item.title}>
						<NavigationLinkItem item={item} />
					</li>
				)
			})}
		</ul>
	)
}
