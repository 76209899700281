import { useBrandSelectedPosition } from 'header/hooks/useBrandSelectedPosition'
import { useColumnPositionTop } from 'header/hooks/useColumnPositionTop'
import { useMenus } from 'header/hooks/useMenus'
import type { EntryParent, MenuEntry } from 'header/types/menu'
import { manageFocusedElementColumnsLPlus } from 'header/utils/menu/focusOrder/manageFocusedElementColumnsLPlus'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useEffect, useRef, useState } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import { Entry } from '../Entry/Entry'

import styles from './ColumnsLPlus.module.scss'

type ColumnsLPlusProps = {
	readonly menus: MenuEntry[] | undefined
	readonly subLabel?: string
	readonly selectedEntries: string[]
	readonly setSelectedEntries: (selectedEntries: string[]) => void
}

export function ColumnsLPlus({
	menus,
	subLabel,
	selectedEntries,
	setSelectedEntries,
}: ColumnsLPlusProps) {
	const [visibleMenus, setVisibleMenus] = useState<(MenuEntry[] | undefined)[]>(
		[menus]
	)
	const [parent, setParent] = useState<EntryParent | undefined>()
	const { t } = useLabels()
	const {
		subBrandSelected,
		refs: { refBrands, refCloseButton },
	} = useMenus()
	const refWrapper = useRef<HTMLDivElement>(null)
	const columnPosition = useColumnPositionTop(refWrapper)
	const brandSelectedIndex = useBrandSelectedPosition()

	const updateSelectedEntry = (entry: MenuEntry, index: number) => {
		const newVisibleMenus = visibleMenus.slice(0, index + 1)

		if (index === 0) {
			setParent({ id: entry.id, labelId: entry.labelId })
		}

		if (entry.menus) {
			newVisibleMenus.push(entry.menus)
		}

		setVisibleMenus(newVisibleMenus)

		const newSelectedEntries = selectedEntries.slice(0, index)
		newSelectedEntries.push(entry.id)
		setSelectedEntries(newSelectedEntries)
	}

	useEffect(() => {
		if (menus) {
			setVisibleMenus([menus])
			setSelectedEntries([])
		}
	}, [menus])

	return (
		<>
			{subLabel && <p className={styles.subLabel}>{t(subLabel)}</p>}
			<div
				className={styles.columnsWrapper}
				ref={refWrapper}
				style={
					{
						'--column-start-top': `${columnPosition}`,
					} as React.CSSProperties
				}
			>
				{visibleMenus?.map((column, index) => {
					const key = `column${index}`
					const isFirstColumn = index === 0
					const dataTestIdCategory = isFirstColumn ? 'section' : 'family'
					const dataTestIdColumn =
						parent && !isFirstColumn ? `menu.family.${parent.id}` : null
					return (
						<ul
							className={cx(
								styles.column,
								applyStylesIf(
									!!subBrandSelected && !isFirstColumn,
									styles.fullWidth
								)
							)}
							key={key}
							data-testid={dataTestIdColumn}
						>
							{column?.map((entry) => {
								const entryKey =
									parent && !isFirstColumn
										? `${parent.id}.${entry.id}`
										: entry.id
								const isEntrySelected = selectedEntries[index]
									? selectedEntries[index] === entry.id
									: undefined

								const analyticsCategory =
									!isFirstColumn && parent
										? {
												section: parent.labelId,
												subsection: entry.labelId,
											}
										: { section: entry.labelId }
								return (
									<li
										key={entryKey}
										data-testid={`menu.${dataTestIdCategory}.${entry.id}`}
									>
										<Entry
											section={entry}
											isSelected={isEntrySelected}
											dataTestIdCategory={dataTestIdCategory}
											analyticsCategory={analyticsCategory}
											onClick={() => updateSelectedEntry(entry, index)}
											handleKeyDown={(event) =>
												manageFocusedElementColumnsLPlus({
													event,
													entry,
													index,
													refWrapper,
													selectedEntries,
													visibleMenus,
													refBrands,
													brandSelectedIndex,
													refCloseButton,
												})
											}
										/>
									</li>
								)
							})}
						</ul>
					)
				})}
			</div>
		</>
	)
}
