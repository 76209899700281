import type { MenuEntry } from 'header/types/menu'
import { useLabels } from 'labels/hooks/useLabels/useLabels'

import { useMenus } from '../../../../hooks/useMenus'
import { Entry } from '../Entry/Entry'
import { InteriorMenu } from '../InteriorMenu/InteriorMenu'

import styles from './Columns.module.scss'

type ColumnsSMProps = {
	readonly menus: MenuEntry[] | undefined
	readonly subLabel?: string
	readonly parent?: MenuEntry
}

export function ColumnsSM({ menus, subLabel, parent }: ColumnsSMProps) {
	const { selectedEntry, updateSelectedEntry } = useMenus()
	const { t } = useLabels()

	const isInteriorMenu = !!parent // only passed in for interior menus
	const dataTestIdCategory = isInteriorMenu ? 'family' : 'section'

	const openInteriorMenu = (entryId: string | null) => {
		updateSelectedEntry(entryId)
	}

	const goBackAction = () => {
		updateSelectedEntry(null)
	}

	return (
		<ul
			className={styles.column}
			data-testid={isInteriorMenu ? `menu.family.${parent.id}` : null}
		>
			{subLabel && <p className={styles.subLabel}>{t(subLabel)}</p>}
			{menus?.map((entry) => {
				const isEntrySelected = selectedEntry === entry.id || undefined

				const analyticsCategory = isInteriorMenu
					? { section: parent.labelId, subsection: entry.labelId }
					: { section: entry.labelId }
				return (
					<li
						key={entry.id}
						data-testid={`menu.${dataTestIdCategory}.${entry.id}`}
					>
						<Entry
							section={entry}
							isSelected={isEntrySelected}
							dataTestIdCategory={dataTestIdCategory}
							analyticsCategory={analyticsCategory}
							onClick={() => openInteriorMenu(entry.id)}
						/>
						{isEntrySelected && entry.menus && (
							<InteriorMenu
								title={entry.labelId}
								goBackAction={goBackAction}
								menus={entry.menus}
								parent={entry}
							/>
						)}
					</li>
				)
			})}
		</ul>
	)
}
