'use client'

import { useCookiesContext } from 'cookies/hooks/useCookiesContext'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { useEffect, useState } from 'react'
import { PageType } from 'types/analytics'

const BANNER_DISMISSAL_KEY = 'isBannerDismissed'

export function useShowBanner() {
	const [showBanner, setShowBanner] = useState(false)
	const { isShop, isMobile, pageType: currentPageType } = useMasterData()
	const { cookiesConsent } = useCookiesContext()
	const isClientEnvironment =
		typeof navigator !== 'undefined' && typeof sessionStorage !== 'undefined'

	const userAgent = isClientEnvironment ? navigator.userAgent : ''
	const isAndroid = /Android/i.test(userAgent) && isMobile

	const isCorrectPage =
		currentPageType !== PageType.ShoppingCart &&
		currentPageType !== PageType.ConfirmationPage

	const hasUserClosedBanner = isClientEnvironment
		? sessionStorage.getItem(BANNER_DISMISSAL_KEY)
		: null

	const userHasInteractedWithCookiesBanner = cookiesConsent !== undefined

	const deviceWebCriteria = isShop && isAndroid && isCorrectPage
	const userCriteria =
		!hasUserClosedBanner && userHasInteractedWithCookiesBanner

	useEffect(() => {
		if (deviceWebCriteria && userCriteria) {
			setShowBanner(true)
		}
	}, [
		isShop,
		isAndroid,
		isCorrectPage,
		hasUserClosedBanner,
		userHasInteractedWithCookiesBanner,
	])

	const closeBanner = () => {
		setShowBanner(false)
		sessionStorage.setItem(BANNER_DISMISSAL_KEY, 'true')
	}

	return { showBanner, closeBanner }
}
