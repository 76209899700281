import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { getBrandTranslationKeys } from 'country-configuration/utils/brands/getBrandTranslationKeys'
import { ButtonIconTransparent } from 'fukku/Button/Icon/Transparent'
import { CommonGenericEvents } from 'header/constants'
import { brandAdapter } from 'header/constants/analytics/brandAdapter'
import { useMenus } from 'header/hooks/useMenus'
import { IconKeyboardReturn } from 'icons/components/IconKeyboardReturn'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import type { BrandId } from 'types/brands'
import { cx } from 'utils/cx'

import { CloseButton } from '../CloseButton/CloseButton'

import styles from './Breadcrumb.module.scss'
import text from 'fukku/styles/texts.module.scss'

interface BreadcrumbProps {
	readonly breadcrumbTitle: string
	readonly prev: () => void
}

export function Breadcrumb({ breadcrumbTitle, prev }: BreadcrumbProps) {
	const { brandSelected, subBrandSelected, updateSelectedEntry } = useMenus()
	const { country } = useMasterData()
	const { t } = useLabels()
	const brandLabelId = getBrandTranslationKeys(
		(subBrandSelected || brandSelected) as BrandId,
		country.brands
	)?.labelKey

	const handleReturnButton = () => {
		const sectionName = breadcrumbTitle + '_close'
		const analyticsData = subBrandSelected
			? {
					brand: brandAdapter[brandSelected as string],
					subbrand: brandAdapter[subBrandSelected],
					section: sectionName,
				}
			: {
					brand: brandAdapter[brandSelected as string],
					section: sectionName,
				}

		sendGenericEvent(CommonGenericEvents.CLICK_MENU, analyticsData)
		prev()
	}

	const action = () => {
		updateSelectedEntry(null)
	}

	return (
		<section className={styles.breadcrumbs} data-testid='menu.submenu.header'>
			<ButtonIconTransparent
				className={styles.prevButton}
				onClick={handleReturnButton}
				aria-label={t('accessibility.icon.back.text')}
				data-testid='menu.subMenu.back'
			>
				<IconKeyboardReturn width={16} rotateRtl />
			</ButtonIconTransparent>

			<span className={styles.textWrapper}>
				<span className={cx(text.uppercaseL, styles.breadcrumbTitle)}>
					{t(breadcrumbTitle)}
				</span>
				<span className={cx(text.uppercaseS, styles.brand)}>
					{brandLabelId ? t(brandLabelId) : null}
				</span>
			</span>

			<CloseButton action={action} />
		</section>
	)
}
