'use client'

import {
	SiteClientLink,
	type SiteClientLinkProps,
} from 'links/site/client/SiteClientLink'
import { cx } from 'utils/cx'

import {
	BUTTON_INSPIRATIONAL_STATE,
	BUTTON_INSPIRATIONAL_VARIANT,
	LINK_SIZE,
	linkInspirationalSizeStyles,
	stateStyles,
} from '../../Inspirational/constants'

import styles from '../../Inspirational/ButtonInspirational.module.scss'

type ClientInspirationalLinkProps = SiteClientLinkProps & {
	readonly size?: LINK_SIZE
	readonly catalogId?: string
	readonly variant?: BUTTON_INSPIRATIONAL_VARIANT
	readonly state?: BUTTON_INSPIRATIONAL_STATE
}

export function InspirationalClientLink(
	props: ClientInspirationalLinkProps
): React.ReactNode {
	const {
		children,
		state = BUTTON_INSPIRATIONAL_STATE.DEFAULT,
		variant = BUTTON_INSPIRATIONAL_VARIANT.DEFAULT,
		size = LINK_SIZE.M,
		className,
		...rest
	} = props

	return (
		<SiteClientLink
			{...rest}
			className={cx(
				className,
				styles.buttonInspirational,
				styles[variant],
				linkInspirationalSizeStyles[size],
				stateStyles[state]
			)}
		>
			<span className={styles.text}>{children}</span>
		</SiteClientLink>
	)
}
