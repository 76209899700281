'use client'

import { useEffect, useRef, useState } from 'react'
import type { ChangeEvent, FocusEvent } from 'react'
import { applyStylesIf, cx } from 'utils/cx'

import type { InputProps } from '../types'
import { ClearOptionButton } from './ClearOptionButton'
import { ErrorMessage } from './ErrorMessage'
import { HintMessage } from './HintMessage'
import { PlaceholderSrOnly } from './PlaceholderSrOnly'

import styles from './Input.module.scss'
import texts from 'fukku/styles/texts.module.scss'

export function Input({
	children,
	disabled = false,
	errorMessage,
	errorMessageDataTestId,
	errorPlaceholders,
	hintMessage,
	hintPlaceholders,
	id,
	label,
	labelFocused = label,
	ariaLabelClearOption = '',
	name = id,
	onBlur = () => {
		/* do nothing */
	},
	onChange = () => {
		/* do nothing */
	},
	onFocus = () => {
		/* do nothing */
	},
	onInput = () => {
		/* do nothing */
	},
	placeholder = '',
	type = 'text',
	value = '',
	forceHint = false,
	showAsTextArea = false,
	withClearOption = false,
	className,
	refCallback = () => {},
	...inputAttributes
}: Readonly<InputProps>): React.ReactNode {
	const [inputValue, setInputValue] = useState<InputProps['value']>(value)
	const [focused, setFocused] = useState<boolean>(false)
	const [showHint, setShowHint] = useState<boolean>(forceHint)
	const inputRef = useRef<(HTMLTextAreaElement & HTMLInputElement) | null>(null)
	const inputRefCallback = (
		event: HTMLTextAreaElement & HTMLInputElement
	): void => {
		refCallback(event)
		inputRef.current = event
	}
	const Component = showAsTextArea ? 'textarea' : 'input'
	const isFilled = inputValue !== ''
	const isFocusedOrFilled = focused || isFilled
	const showClearOption = withClearOption && inputValue !== ''
	const errorMessageId = `${id}-error`
	const hintMessageId = `${id}-hint`

	useEffect(() => {
		if (value !== inputValue) {
			setInputValue(value)
		}
	}, [value])

	const handleClear = (): void => {
		setInputValue('')

		if (inputRef.current) {
			inputRef.current.focus()
		}
	}

	const handleChange = (
		e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		const { target } = e
		setInputValue(target.value)
		onChange(target.value)
	}

	const handleFocus = (
		e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		setFocused(true)
		if (hintMessage) {
			setShowHint(true)
		}
		onFocus(e)
	}

	const handleBlur = (
		e: FocusEvent<HTMLTextAreaElement | HTMLInputElement>
	): void => {
		setFocused(false)
		if (hintMessage) {
			setShowHint(false)
		}
		onBlur(e)
	}

	const getDescriberId = (): string | undefined => {
		if (errorMessage) {
			return errorMessageId
		}

		if (showHint) {
			return hintMessageId
		}

		return undefined
	}

	return (
		<div className={styles.inputWrapper}>
			<div
				className={cx(
					styles.box,
					applyStylesIf(isFocusedOrFilled, styles.focused),
					applyStylesIf(!!errorMessage, styles.error),
					applyStylesIf(disabled, styles.disabled),
					className
				)}
			>
				<label
					className={cx(
						styles.label,
						applyStylesIf(isFocusedOrFilled, styles.focusedLabel),
						isFocusedOrFilled ? texts.uppercaseS : texts.bodyM
					)}
					htmlFor={id}
				>
					{isFocusedOrFilled ? labelFocused : label}
				</label>
				<Component
					aria-describedby={getDescriberId()}
					aria-invalid={!!errorMessage}
					className={cx(
						texts.inputText,
						styles.input,
						applyStylesIf(isFocusedOrFilled, styles.focusedInput),
						applyStylesIf(showClearOption, styles.content)
					)}
					readOnly={disabled}
					disabled={disabled}
					id={id}
					name={name}
					onBlur={handleBlur}
					onChange={handleChange}
					onFocus={handleFocus}
					onInput={onInput}
					placeholder={placeholder}
					type={type}
					value={inputValue}
					ref={inputRefCallback}
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...inputAttributes}
				/>
				<ClearOptionButton
					className={styles.clearButton}
					ariaLabelClearOption={ariaLabelClearOption}
					onClear={handleClear}
					shouldRender={showClearOption}
				/>
				{children}
			</div>
			<PlaceholderSrOnly placeholder={placeholder} />
			<HintMessage
				id={hintMessageId}
				hintPlaceholders={hintPlaceholders}
				shouldRender={showHint && !errorMessage}
			>
				{hintMessage}
			</HintMessage>
			<ErrorMessage
				id={errorMessageId}
				errorPlaceholders={errorPlaceholders}
				data-testid={errorMessageDataTestId}
			>
				{errorMessage}
			</ErrorMessage>
		</div>
	)
}
