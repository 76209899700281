'use client'

import { useBagTotalItems } from 'cart/hooks/queries/useBagTotalItems'
import { useAnalytics } from 'cart/hooks/useAnalytics'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import replacePlaceholders from 'labels/lib/replacePlaceholders'
import { useEffect, useRef } from 'react'

import styles from './TotalItems.module.scss'

interface Props {
	readonly withLabel?: boolean
	readonly manageFocus?: (itemRemoved: boolean) => void
	readonly icon?: boolean
}

export const TotalItems = ({
	withLabel,
	manageFocus,
	icon,
}: Props): React.ReactNode => {
	const { t } = useLabels()
	const { totalItems, error } = useBagTotalItems()
	const { sendBagErrorTotalItemsEvent } = useAnalytics()

	const numItems = useRef<number | null>(null)

	useEffect(() => {
		if (totalItems && manageFocus) {
			if (numItems.current) {
				const itemRemoved = numItems.current > totalItems
				manageFocus(itemRemoved)
			}
			numItems.current = totalItems
		}
	}, [totalItems, manageFocus])

	useEffect(() => {
		if (error) {
			sendBagErrorTotalItemsEvent()
		}
	}, [error])

	const buttonClassName = withLabel ? styles.large : styles.small

	const headerCartText: string =
		totalItems === undefined
			? t('header.shoppingBag.noVariable.text')
			: replacePlaceholders(t('header.shoppingBag.text'), {
					1: totalItems || 0,
				})

	return (
		<span data-testid='bag.header.totalItems' className={buttonClassName}>
			{icon ? totalItems : headerCartText}
		</span>
	)
}
