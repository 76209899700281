import { IconCloseMedium } from 'icons/components/IconCloseMedium'

import type { ClearOptionButtonProps } from '../types'

export function ClearOptionButton({
	ariaLabelClearOption,
	onClear: handleClear,
	shouldRender,
	className = '',
}: Readonly<ClearOptionButtonProps>) {
	if (!shouldRender) {
		return null
	}

	return (
		<button
			aria-expanded
			aria-label={ariaLabelClearOption}
			className={className}
			onClick={handleClear}
			type='button'
			data-testid='clearInput'
		>
			<IconCloseMedium />
		</button>
	)
}
