'use client'

import { ClientLink, type ClientLinkProps } from 'utils/link/client'

import { MY_ACCOUNT_LINK_HREF } from '../constants/myAccountLink'

export type MyAccountClientLinkProps = Omit<ClientLinkProps, 'href'> & {
	readonly linkComponent?: React.ElementType
}

export function MyAccountClientLink({
	children,
	linkComponent,
	...linkProps
}: MyAccountClientLinkProps) {
	const props: Omit<ClientLinkProps, 'prefetch'> = {
		...linkProps,
		children,
		href: MY_ACCOUNT_LINK_HREF,
		withLegacyLocale: false,
	}
	const Component = linkComponent || ClientLink

	return <Component {...props}>{children}</Component>
}
