'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { CommonGenericEvents } from 'header/constants'
import { brandAdapter } from 'header/constants/analytics/brandAdapter'
import { HoverDelayInspiration } from 'header/constants/hoverDelays'
import { useHover } from 'header/hooks/useHover'
import { useMenus } from 'header/hooks/useMenus'
import { getFirstAndLastElement } from 'header/utils/menu/focusOrder/getFirstAndLastElement'
import { getActiveBrandTopbar } from 'header/utils/menu/getActiveBrandTopbar'
import { IconDownSmall } from 'icons/components/IconDownSmall'
import { IconUpSmall } from 'icons/components/IconUpSmall'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { usePathname } from 'next/navigation'
import { useEffect, useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import type { Brand } from 'types/brands'
import { Keyboard } from 'types/keyboard'

import { ContentSM } from '../../Content/ContentSM'
import { InspirationalButtonLink } from '../../InspirationalLinkBase/InspirationalButtonLink/InspirationalButtonLink'
import { InspirationalLink } from '../../InspirationalLinkBase/InspirationalLink/InspirationalLink'
import { ID_STRUCTURE_CONTENT } from '../../Structure/StructureLPlus'
import type { BrandMenu } from '../Brands'

import styles from './BrandEntry.module.scss'

type BrandEntryProps = {
	readonly brand: BrandMenu
	readonly brandIndex: number
	readonly isTopBar: boolean
	readonly handleOpenMenu?: () => void
	readonly defaultBrand?: Brand
}

export function BrandEntry({
	brand,
	brandIndex,
	isTopBar,
	handleOpenMenu,
	defaultBrand,
}: BrandEntryProps) {
	const { isLargeOrGreater } = useResponsive()
	const {
		open,
		brandSelected,
		openingWithKeyboardFocus,
		refs: { refContent, refCloseButton },
		updateBrandSelected,
		updateOpeningWithKeyboardFocus,
	} = useMenus()
	const { t } = useLabels()
	const pathname = usePathname()
	const refAccesibilityButton = useRef<HTMLButtonElement>(null)

	const isBrandActive = getActiveBrandTopbar(
		brandSelected,
		open,
		isTopBar,
		brand,
		defaultBrand
	)

	const isBrandMenuOpen = open && brandSelected === brand.id

	const { id, labelKey: labelId, url } = brand
	const label = t(labelId)

	const hoverUpdateBrandSelected = () => {
		updateBrandSelected(id)
		updateOpeningWithKeyboardFocus(false)
	}

	const { ref: refBrandEntry, isHovering } = useHover<HTMLLIElement>(
		HoverDelayInspiration.BRANDS,
		hoverUpdateBrandSelected
	)

	const handleButtonClick = (isAccesibilityButton: boolean) => {
		updateBrandSelected(id)
		if (!isLargeOrGreater) {
			sendGenericEvent(CommonGenericEvents.CLICK_MENU, {
				brand: brandAdapter[id],
				from: brandAdapter[brandSelected as string],
			})
		} else {
			handleOpenMenu && handleOpenMenu()
		}
		updateOpeningWithKeyboardFocus(isAccesibilityButton)
	}

	const handleLinkClick = () => {
		sendGenericEvent(CommonGenericEvents.CLICK_MENU, {
			brand: brandAdapter[id],
		})
	}

	const handleBrandEntryKeyDown = (event: React.KeyboardEvent) => {
		const isFirstElement = brandIndex === 0
		const isTabShiftKey = event.key === Keyboard.Tab && event.shiftKey

		const shouldFocusCloseButton =
			isTabShiftKey && isTopBar && refCloseButton.current && isFirstElement
		if (shouldFocusCloseButton) {
			event.preventDefault()
			refCloseButton.current.focus()
		}
	}

	const handleAccesibilityButtonKeyDown = (event: React.KeyboardEvent) => {
		if (
			event.key === Keyboard.Tab &&
			!event.shiftKey &&
			isBrandMenuOpen &&
			refContent.current
		) {
			event.preventDefault()
			const { firstElement } = getFirstAndLastElement(refContent.current)
			firstElement.focus()
		}
	}

	useEffect(() => {
		if (
			isBrandMenuOpen &&
			isTopBar &&
			isLargeOrGreater &&
			openingWithKeyboardFocus
		) {
			refAccesibilityButton.current?.focus()
		}
	}, [
		open,
		isTopBar,
		isLargeOrGreater,
		refAccesibilityButton.current,
		openingWithKeyboardFocus,
	])

	useEffect(() => {
		if (isHovering && handleOpenMenu) {
			handleOpenMenu()
		}
	}, [isHovering])

	return (
		<li
			className={styles.brandLi}
			ref={isLargeOrGreater ? refBrandEntry : undefined}
			data-testid={`menu.brand.${id}.hover`}
		>
			{isLargeOrGreater ? (
				<>
					<InspirationalLink
						href={url || ''}
						isLocalizedPath
						className={styles.brandEntry}
						linkProps={{
							'data-testid': `menu.brand.${id}`,
							rel: 'follow',
							'aria-current': pathname === url ? 'page' : undefined,
							onKeyDown: handleBrandEntryKeyDown,
						}}
						onClick={handleLinkClick}
						active={isBrandActive}
						isTopBar={isTopBar}
					>
						{label}
					</InspirationalLink>
					<button
						ref={refAccesibilityButton}
						className={styles.accessibilityButton}
						onClick={() => handleButtonClick(true)}
						onKeyDown={handleAccesibilityButtonKeyDown}
						aria-label={label}
						aria-expanded={isBrandMenuOpen}
						aria-controls={ID_STRUCTURE_CONTENT}
					>
						{isBrandMenuOpen ? (
							<IconUpSmall width={20} height={20} />
						) : (
							<IconDownSmall width={20} height={20} />
						)}
					</button>
				</>
			) : (
				<>
					<InspirationalButtonLink
						className={styles.brandEntry}
						data-testid={`menu.brand.${id}`}
						onClick={() => handleButtonClick(false)}
						active={isBrandActive}
						aria-selected={isBrandActive}
						isTopBar={isTopBar}
					>
						{label}
					</InspirationalButtonLink>

					{isBrandActive && <ContentSM />}
				</>
			)}
		</li>
	)
}
